$c-primary: #303230;
$c-secandary: #303230;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;

      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  padding-left: 1.5rem;

  label {
    display: block;
    position: relative;
    align-items: center;
    padding-left: 0.4375rem; //7
    font-size: 1rem;
    line-height: 1.25rem;
  }

  label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1.25rem; //18
    height: 1.25rem; //18
    left: 0;
    margin-left: -1.5rem; //24
    border-radius: 0.1875rem; //3
    border: 1px solid #c6c6c6;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  label::after {
    content: '';
    position: absolute;
    width: 0.375rem; //6
    height: 0.75rem; //12
    margin-left: -1.5rem; //24
    top: 0.3125rem; //5
    left: 0.4375rem; //7
    margin-top: -0.1875rem; //-3
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before {
    border: 1px solid var(--secondary);
  }
  input:checked + label::before,
  input:checked + label::after {
    background: var(--secondary);
    display: block;
  }
}
