$c-primary: #303230;
$c-secandary: #303230;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;

      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
