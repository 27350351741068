$c-primary: #303230;
$c-secandary: #303230;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;

      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  :global .react-calendar {
    width: 300px;
  }

  .calendar {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .closed {
    display: none;
  }
}

:global .react-calendar__tile {
  color: black;
}
